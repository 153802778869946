import { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import {
  addInsightJobVideo,
  getVideos,
  editVideo,
  deleteVideo,
  addPartialFile,
} from "services";
import { useSelector, useDispatch } from "react-redux";
import {
  addVideos,
  // toggleMobileLoading,
} from "store/reducers/mobilePreviewSlice";
import { runValidator } from "utils";

const useInsightVideo = (fileSizeError) => {
  const videoRef = useRef(null);
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileId = useSelector((state) => state.user.profileId);
  const [videoDetails, setVideoDetails] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [model, setModel] = useState({
    videoDataBase64: "",
    name: "",
    type: 1,
    profileId,
  });
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState({});
  const INPUTS = {
    name: {
      rules: { required: true, maxLength: 50 },
      errorMessage: "Bitte Titel mit max. 50 Zeichen eingeben",
    },
    videoDataBase64: {
      rules: { required: true },
      errorMessage: "Bitte ein Video hochladen",
    },
  };

  useEffect(() => {
    if (location?.state) {
      const { video } = location?.state;
      setVideoDetails(video);
      setModel({
        ...model,
        name: video.jobName ?? "",
        videoDataBase64: video.videoDataBase64 ?? "",
      });
    } else {
      setVideoDetails(false);
      setModel({ ...model, name: "" });
    }
  }, [location]);

  const handleModelChange = (name, value) => {
    const payload = { ...model, [name]: value };

    if (
      videoDetails.jobName !== payload.name ||
      videoDetails.videoDataBase64 !== payload.videoDataBase64
    ) {
      setIsUpdated(true);
    } else {
      setIsUpdated(false);
    }

    setModel({ ...payload });
    console.log(payload);
    console.log("FileSizeError: ", fileSizeError);
  };
  const isUploadButtonDisabled = () => {
    const { name, videoDataBase64 } = model;
    return !(videoDataBase64 && name && !fileSizeError);
  };

  const isEditButtonDisabled = () => {
    const { name } = model;
    return !(name && !fileSizeError);
  };

  const handleDeleteVideo = () => {
    setLoading(true);
    deleteVideo({ videoId: videoDetails.videoId }).then((result) => {
      if (result) {
        getVideos({ company: model.profileId, provideAddresses: true }).then(
          (result) => {
            dispatch(addVideos(result));
            setVideoDetails(false);
            setLoading(false);
            navigate("/");
          }
        );
      } else {
        setLoading(false);
        navigate("/");
      }
    });
  };

  const validateAll = () => {
    let errors = {};
    for (let field in INPUTS) {
      let fieldErrors = runValidator(model[field], INPUTS[field].rules);
      let errorMessage = INPUTS[field].errorMessage;
      if (fieldErrors.length > "0") {
        errors[field] = errorMessage;
      }
    }
    return errors;
  };

  const validateName = () => {
    let errors = {};
    let fieldErrors = runValidator(model.name, INPUTS.name.rules);
    let errorMessage = INPUTS.name.errorMessage;
    if (fieldErrors.length > "0") {
      errors.name = errorMessage;
    }
    return errors;
  };

  const handleSubmit = async () => {
    let errors = validateAll();
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }
    setLoading(true);
    setProgress(1);
    let fileIds = await uploadVideoInParts(model.videoDataBase64);
    addInsightJobVideo({
      profileId: model.profileId,
      type: model.type,
      name: model.name,
      partialFileIds: fileIds,
    }).then((result) => {
      if (result?.videoId) {
        getVideos({ company: model.profileId, provideAddresses: true }).then(
          (result) => {
            setProgress(100);
            dispatch(addVideos(result));
            setLoading(false);
            navigate("/");
          }
        );
      } else {
        setLoading(false);
      }
    });
  };

  const handleEditVideo = async () => {
    let errors = validateName();
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }
    setLoading(true);

    let fileIds = [];
    if (model.videoDataBase64) {
      setProgress(1);
      fileIds = await uploadVideoInParts(model.videoDataBase64);
      editVideo({
        profileId: model.profileId,
        type: model.type,
        name: model.name,
        videoId: videoDetails.videoId,
        partialFileIds: fileIds,
      }).then((result) => {
        if (result?.videoId) {
          getVideos({ company: model.profileId, provideAddresses: true }).then(
            (result) => {
              setProgress(100);
              dispatch(addVideos(result));
              setLoading(false);
              navigate("/");
            }
          );
        } else {
          setLoading(false);
        }
      });
    } else {
      editVideo({
        profileId: model.profileId,
        type: model.type,
        name: model.name,
        videoId: videoDetails.videoId,
        partialFileIds: fileIds,
      }).then((result) => {
        if (result?.videoId) {
          getVideos({ company: model.profileId, provideAddresses: true }).then(
            (result) => {
              setProgress(100);
              dispatch(addVideos(result));
              setLoading(false);
              navigate("/");
            }
          );
        } else {
          setLoading(false);
        }
      });
    }
  };

  const uploadVideoInParts = async (base64Video) => {
    const partSize = 1024 * 512; // 1MB
    const totalParts = Math.ceil(base64Video.length / partSize);
    let start = 0;
    let end = partSize;
    let chunkNumber = 1;
    const tempPrtialFileIds = new Array(totalParts).fill(null);
    let currentProgress = 0;
    const maxConcurrentUploads = 5;
    let activeUploads = 0;

    const uploadPart = async (partialB64Data, reference) => {
      const result = await addPartialFile({ partialB64Data, reference });
      return result;
    };

    const uploadNextChunk = async () => {
      if (start >= base64Video.length) {
        return;
      }

      const partialB64Data = base64Video.substring(start, end);
      const currentChunkNumber = chunkNumber;

      start = end;
      end = start + partSize;
      chunkNumber++;

      activeUploads++;

      try {
        const result = await uploadPart(partialB64Data, currentChunkNumber);
        tempPrtialFileIds[currentChunkNumber - 1] = result.id; // Insert the result in the correct position
        console.log(tempPrtialFileIds);
        currentProgress++;
        setProgress((currentProgress / totalParts) * 100 - 1);
      } catch (error) {
        console.error("Error uploading partial files:", error);
      } finally {
        activeUploads--;
        // Try uploading the next chunk when one completes
        uploadNextChunk();
      }
    };

    // Start initial uploads
    for (
      let i = 0;
      i < maxConcurrentUploads && start < base64Video.length;
      i++
    ) {
      uploadNextChunk();
    }

    // Wait until all uploads are done
    while (activeUploads > 0) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Polling every 100ms
    }

    // Wait for a short duration to simulate smoother progression to 100%
    await new Promise((resolve) => setTimeout(resolve, 500));
    // setProgress(100);

    return tempPrtialFileIds;
  };

  return {
    navigate,
    model,
    handleModelChange,
    handleSubmit,
    loading,
    progress,
    videoDetails,
    handleEditVideo,
    errors,
    isUpdated,
    handleDeleteVideo,
    isUploadButtonDisabled,
    isEditButtonDisabled,
    videoRef,
  };
};

export default useInsightVideo;
