import LoaadingImg from "assets/img/Loading-pana.png";
import { useState, useEffect } from "react";
import "assets/css/stylesheet.scss";
import { ApplicationRoutes, AuthorizationRoutes } from "routes";
import Sidebar from "components/sidebar";
import NotificationBar from "components/notification-bar";
import { NotAvailableIcon } from "assets/img/icons";
import { getCustomerProfile } from "services";
import { useDispatch } from "react-redux";
import { userLogin } from "store/reducers/userSlice";
import Loader from "components/loader";
import { useSearchParams } from "react-router-dom";
import { clarity } from "react-microsoft-clarity";

function App() {
  if (process.env.NODE_ENV === "production") clarity.init("pqbbciv6ed");
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const session = searchParams.get("session");

    if (session) {
      // Save session to localStorage
      localStorage.setItem("session", session);

      // Remove session query from URL
      const urlWithoutSession =
        window.location.origin + window.location.pathname;
      window.history.replaceState(null, "", urlWithoutSession);

      // Reload the page
      window.location.reload();
    } else {
      const storedSession = localStorage.getItem("session");

      // Fetch user profile if session exists in localStorage
      if (storedSession) {
        getCustomerProfile().then((result) => {
          if (Object.keys(result).length > 0) {
            const {
              active,
              contactCustomerSupport,
              customerPortalLink,
              endOfSubscription,
              paymentWidgetId,
              paymentWidgetKey,
              showPaymentWidget,
              profileId,
              clientReferenceId,
              stripeHtmlProductTable,
            } = result;

            if (profileId) {
              setIsLoggedIn(true);
              dispatch(userLogin(result));
              setUserData({
                active,
                contactCustomerSupport,
                customerPortalLink,
                endOfSubscription,
                paymentWidgetId,
                paymentWidgetKey,
                showPaymentWidget,
                profileId,
                clientReferenceId,
                stripeHtmlProductTable,
              });
            }
          } else {
            localStorage.removeItem("session");
            setIsLoggedIn(false);
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, [dispatch, searchParams]);

  return (
    <>
      {loading ? (
        <>
          <div className="loader-screen">
            <div className="loading-image">
              <img src={LoaadingImg} alt="Loading" />
            </div>
            Loading
            <Loader />
          </div>
        </>
      ) : (
        <>
          {isLoggedIn ? (
            <>
              <Sidebar />
              <div className="main-content">
                {userData.active ? null : (
                  <NotificationBar
                    title="Ihr Profil ist nicht live!"
                    description="Aktivieren Sie Ihr Profil in den Einstellungen, um in unserer App angezeigt zu werden!"
                  />
                )}
                <ApplicationRoutes />
              </div>
            </>
          ) : (
            <AuthorizationRoutes />
          )}
        </>
      )}
      <div className="not-available-for-devices">
        <NotAvailableIcon />
        <p>
          {" "}
          Das Unternehmenskonto kann derzeit nur am Desktop bearbeitet werden.
        </p>
      </div>
    </>
  );
}

export default App;
